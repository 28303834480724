import React from 'react'
import {Table, Icon, Header, Segment} from 'semantic-ui-react'

import _ from 'lodash'

export default function Attendance({attd, profile, sett}){
    

  
    const getCurrentAttendance = () => {

        let all_atd = _.filter(attd,(ca) => {return ca.class === profile.class_admitted && (ca.term === sett.current_term || ca.term === sett.current_semester) && ca.year === new Date().getFullYear()}) 
        let cc_attendance = _.sortBy(all_atd, (ad) => new Date(ad.date).getTime()).reverse()
        let totalAttendance = 0, attendanceR = []
        if(cc_attendance.length >= 1){
           
            for(var atd in cc_attendance){
                for(var pd in cc_attendance[atd].data){
                    if(cc_attendance[atd].data[pd].student_id === profile.student_id){
                        attendanceR.push({...cc_attendance[atd]})
                        totalAttendance +=1;
                    }
                }
            }
        }

        return {
            total: totalAttendance,
            real:attendanceR
        }
    }

    const isPresentToday = (arr) => {
        let check = _.find(arr, (dt) => {return new Date(dt.date).toLocaleDateString() === new Date().toLocaleDateString()})
        if(check === undefined){
            return <> <Icon name="cancel" color="red"/> Not Present </>
        }else {
            return <> <Icon name="checkmark" color="green" /> Present</>
        }
    }

    return(
        <div className="inner-nodes">
            <Header content="Student Attendance" subheader={profile.last_name + " " + profile.other_names} className="headers" />
            <Segment raised className="padded">
                <Header content={profile.class_category ==='junior_high' ? sett.current_semester : sett.current_term} subheader={"Academic Year: " + sett.academic_year} />
            <Table singleLine unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell colSpan="2">
                            Status Today: {isPresentToday(getCurrentAttendance().real)}
                        </Table.HeaderCell>
                        <Table.HeaderCell>Total: {getCurrentAttendance().total}</Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Time</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {getCurrentAttendance().real.map((atd,idx) => (
                    <Table.Row key={idx}>
                        <Table.Cell>{ new Date(atd.date).toDateString()}</Table.Cell>
                        <Table.Cell>{ new Date(atd.date).toLocaleTimeString()}</Table.Cell>
                        <Table.Cell>
                            <Icon color="green" name="checkmark" /> Present</Table.Cell>
                    </Table.Row>
                    ))}
                </Table.Body>
            </Table>
            </Segment>
        </div>
        
    )
}