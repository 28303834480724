import React from 'react'
import { Modal, Segment, Button } from 'semantic-ui-react'
import generate_receipt from './generates/generate-receipt'

export default function FeeDetails({ open, setOpen, fees }) {

    return (
        <Modal
            size="wide"
            className="details-modal"
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}>
            <Modal.Header>Payment Details</Modal.Header>
            <Modal.Content as={Segment} className="details">
                <div className='dt'>
                    Amount Paid: ${fees.amount_paid}
                </div>

                <div className='dt'>
                    Class : {fees.class_admitted}
                </div>

                <div className='dt'>
                    Paid by: {fees.paid_by}
                </div>
                <div className='dt'>
                    Date : {fees.date_paid}
                </div>
                <div className='dt'>
                    Term : {fees.term_paid}
                </div>

                <div className='dt'>
                    Balance : {fees.balance}
                </div>

                <div className='dt'>
                    Time : {new Date(fees.date_paid).toLocaleTimeString()}
                </div>

                <div className="dt">
                    Received by: {fees.received_by}
                </div>

                <div className='dt'>
                    Reciept ID: {fees.receipt_id}
                </div>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    content="Download"
                    circular
                    size="mini"
                    labelPosition='right'
                    icon='download'
                    onClick={() => generate_receipt(fees)}
                    positive
                />
            </Modal.Actions>


        </Modal>
    )
}