import React from 'react'
import {Link} from 'react-router-dom'
import {Segment, Form, Button, Icon,Image,  Input, Header} from 'semantic-ui-react'
import _ from 'lodash'
import {db, auth} from '../../db'
import logo from '../../images/logo.png'

export default function Register(){
    const [student_id, setStudentID] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [confirm_password, setConfirmPassword] = React.useState('')
    const [authorizedAccounts, setAuthorizedAccounts] = React.useState([])

    React.useEffect(() => {
        db.collection('student_registration').get().then(qss => {
            let authorized = []
            qss.forEach(acc => {
                if(acc.data().status === undefined || acc.data().status === 'active'){
                    authorized.push({...acc.data(), _id: acc.id})
                }
            })
            setAuthorizedAccounts(authorized); authorized = []
        })
    },[])

    const addNewPortal = event => {
        //check for accessibility 
        let status = _.find(authorizedAccounts,(std) => {return std.student_id === student_id})
        if(status === undefined){
            // cannot register: contact Administrator
            alert('Student ID not Recognized, Please contact Administrator')
        }else {
            //status is available
            //check for password equality 
            if(password !== confirm_password){
                alert('passwords do not match')
            }else{
                // add new account
                let account_mail = student_id.trim() + "@wrisgh.com"
                auth().createUserWithEmailAndPassword(account_mail,password).then(resp => {
                    
                }).catch(err =>{
                    alert(err.message)
                })
            }

        }

        
    }
    return(
        <Segment raised className="form-container">
            <Image size="small" circular src={logo} alt="www.thelogickids.com"/>
            <Header as="h1" inverted textAlign="center" content="New Registration" subheader="Add Your Ward to the Portal" />
            <Segment raised className="form-content">
            <Form onSubmit={addNewPortal} >
                <Form.Field control={Input} label="Student ID" required onChange={(e) => setStudentID(e.target.value)} value={student_id} />
                <Form.Field control={Input} label="Password" type="password" minLength="5" required onChange={(e) => setPassword(e.target.value)} value={password} />
                <Form.Field control={Input} label="Confirm Password" type="password" minLength="5" required onChange={(e) => setConfirmPassword(e.target.value)} value={confirm_password} />
                <Button circular icon color="green">
                    <Icon name="send"/>
                </Button>
            </Form>
            </Segment>
            <p>Registed your ward? <Link to="/login">Sign In to Application</Link></p>
            <p>Powered by <a target="_blank" rel="noopener noreferrer" href="http://www.thelogickids.com">&copy; Logicians Ghana</a></p>
    
        </Segment>
    )
}