import React from 'react'
import { Menu, Icon, Sidebar } from 'semantic-ui-react'


export default function BottomNav({ page, setPage }) {
    const [visible, setVisible] = React.useState(false);
    return (
        <>
            <Menu fixed="bottom" widths={4} borderless>

                <Menu.Item onClick={() => setPage('dashboard')} active={page === 'dashboard'} className="bottom-tabs" >
                    <Icon size="large" name='pie chart' color="red"/>
                    <p className="bottom-ps">Dashboard</p>
                </Menu.Item>


                <Menu.Item onClick={() => setPage('fees')} active={page === 'fees'} className="bottom-tabs">

                    <Icon size="large" name='euro' color="teal"/>
                    <p className="bottom-ps">Fees</p>

                </Menu.Item>


                <Menu.Item onClick={() => setPage('news')} active={page === 'news'} className="bottom-tabs">

                    <Icon size="large" name='bell' color="olive"/>
                    <p className="bottom-ps" >News</p>

                </Menu.Item>

                <Menu.Item className="bottom-tabs" onClick={() => setVisible(!visible)}>
                    <Icon size="large" name='bars' />
                    <p className="bottom-ps" >Menu</p>
                </Menu.Item>

            </Menu>

            {/* Side Menu */}
            <Sidebar
                as={Menu}
                direction="right"
                icon="labeled"
                animation='overlay'
                onHide={() => setVisible(false)}
                vertical
                visible={visible}
                width='thin'
            >

                <Menu.Item onClick={() => setPage('profile')} active={page === 'profile'} >
                    <Icon name='user' color="teal" />
                     Profile
                </Menu.Item>

                <Menu.Item onClick={() => setPage('records')} active={page === 'records'} >
                    <Icon.Group size='big'>
                        <Icon name='book' color="teal" />
                        <Icon corner name='pencil' color="red" />
                    </Icon.Group>
                    <br />
                     Records
                </Menu.Item>



                <Menu.Item onClick={() => setPage('attendance')} active={page === 'attendance'} >
                    <Icon name='checkmark' color="green" />
                     Attendance
                </Menu.Item>



                <Menu.Item onClick={() => setPage('assignment')} active={page === 'assignment'}  >
                    <Icon name='book' color="orange" />
                        Assignments
                </Menu.Item>




                <Menu.Item onClick={() => setPage('time_tables')} active={page === 'time_tables'} >
                    <Icon name='table' color="olive" />
                    Time Tables
                </Menu.Item>



                <Menu.Item onClick={() => setPage('staff_profile')} active={page === 'staff_profile'}  >
                    <Icon name='id badge' color="red" />
                     Staff Profile
                </Menu.Item>



                <Menu.Item onClick={() => setPage('classmates')} active={page === 'classmates'} >
                    <Icon name='users' color="brown" />
                    Class Mates
                 </Menu.Item>



                <Menu.Item onClick={() => setPage('calendar')} active={page === 'calendar'} >
                    <Icon name='calendar' color="green" />
             Outline
            </Menu.Item>

            </Sidebar>
        </>
    )
}

