import React from 'react'
import Chart from 'react-apexcharts'
import _ from 'lodash'

export default class ProgressGraph extends React.Component{
    constructor(){
        super()
        this.state = {
                type: 'line',
                options: {
                    chart: {
                        type: 'line',
                        height:300,
                        stacked:false,
                        zoom: {
                            enabled: false
                        },
    
                    }, stroke: {
                        curve: 'smooth'
                    },
                    plotOptions: {
                        bar: {
                          columnWidth: "20%"
                        }
                      },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.9,
                            stops: [0, 90, 100]
                        }
                    },
                    title: {
                        text: 'PERFORMANCE METRICS',
                        align: 'left',
                        style: {
                            fontSize: '15px',
                            fontFamily: 'Sen'
                        },
                        offsetY: 5,
                        offsetX: 5
                    },
                    markers: {
                        size: 6,
                        strokeWidth: 0,
                        hover: {
                            size: 9
                        },
                        style: {
                            fontSize: '12px',
                            fontFamily: 'Sen'
                        },
                    },
                    grid: {
                        show: true,
                        padding: {
                            bottom: 0,
                            top: 10
                        }
                    },
                    dataLabels: {
                        enabled: false
                      },
                    labels: ['Term 1', 'Term 2', 'Term 3'],
                    xaxis: {
                        style: {
                            fontFamily: 'Sen'
                        },
                        tooltip: {
                            enabled: true,
                            style: {
                                fontFamily: 'Sen'
                            },
                        }
                    },
                    legend: {
                        style: {
                            fontFamily: 'Sen'
                        },
                        position: 'top',
                        horizontalAlign: 'center',
                        offsetY: -10
                    }
                },
                
            }
    }

    getPerformanceData = () => {

        let data = this.props.report

        let fTerm =_.find(data, (dt) => dt.term === 'First Term')
        let sTerm = _.find(data, (dt) => dt.term === 'Second Term')
        let tTerm = _.find(data, (dt) => dt.term === 'Third Term')

        let mfT = 0, msT = 0, mtT = 0,
        efT = 0, esT =0, etT = 0,
        sfT = 0, ssT=0, stT = 0, 
        
        ssfT = 0, sssT = 0, sstT = 0, 
        owfT = 0, owsT = 0, owtT = 0,
        ffT = 0, fsT = 0, ftT = 0,
        fnfT = 0, fnsT = 0, fntT = 0,
        ictfT = 0, ictsT = 0, icttT = 0,
        rmfT = 0, rmsT = 0, rmtT = 0,
        hfT = 0, hsT = 0, htT = 0, //eslint-disable-next-line
        bdfT = 0, bdsT = 0, bdtT = 0,
        crafT = 0, crasT = 0, cratT = 0
        
        //get math scores for all terms. 
        if(fTerm !== undefined){
            mfT = fTerm.mts
            efT = fTerm.ets
            sfT = fTerm.sts //eslint-disable-next-line
            ssfT = fTerm.ssts
            owfT = fTerm.owts
            ffT = fTerm.fts 
            fnfT = fTerm.fnts 
            ictfT = fTerm.ictts 
            rmfT = fTerm.rmts
            hfT = fTerm.hts
            crafT = fTerm.crats //eslint-disable-next-line
            bdfT = fTerm.bdtts

        }

        if(sTerm !== undefined){
            msT = sTerm.mts
            esT = sTerm.ets
            ssT = sTerm.sts //eslint-disable-next-line
            sssT = sTerm.ssts
            owsT = sTerm.owts
            fsT = sTerm.fts 
            fnsT = sTerm.fnts 
            ictsT = sTerm.ictts 
            rmsT = sTerm.rmts
            hsT = sTerm.hts
            crasT = sTerm.crats
            bdsT = sTerm.bdtts
        }

        if(tTerm !== undefined){
            mtT = tTerm.mts
            etT = tTerm.ets
            stT = tTerm.sts //eslint-disable-next-line
            sstT = tTerm.ssts 
            owtT = tTerm.owts
            ftT = tTerm.fts 
            fntT = tTerm.fnts 
            ictsT = tTerm.ictts 
            rmsT = tTerm.rmts
            hsT = tTerm.hts
            crasT = tTerm.crats //eslint-disable-next-line
            bdsT = tTerm.bdtts
        }

        return  [
            {
                name: "Maths",
                data: [mfT, msT, mtT],
            },
            {
                name: "English",
                data: [efT, esT, etT]
            },
            {
                name: "Science",
                data: [sfT, ssT, stT]
            },
            {
                name: "O W O P",
                data: [owfT, owsT, owtT]
            },
            {
                name: "R M E",
                data: [rmfT, rmsT, rmtT]
            },
            {
                name: "History",
                data: [hfT, hsT, htT]
            },
            {
                name: "French",
                data: [ffT, fsT, ftT]
            },
            {
                name: "C. Arts",
                data: [crafT, crasT, cratT]
            },
            {
                name: "Fante",
                data: [fnfT, fnsT, fntT]
            },
            {
                name: "I C T",
                data: [ictfT, ictsT, icttT]
            }
            ]
    }


    render(){
        return(
            <Chart options={this.state.options} series={this.getPerformanceData()} type={this.state.type}/>
        )
    }
}