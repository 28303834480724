import React from 'react'
import { Header, Image,Card, Segment } from 'semantic-ui-react'

export default function Classmates({mates,cClass}) {

    return (
        
        <React.Fragment>
        <div className="inner-nodes">
            <Header content="Classmates" subheader={cClass} className="headers" />
            <Segment raised className="padded">
            <Card.Group>
                {mates.map(std => (
                    <Card raised key={std.student_id} centered>
                    <Card.Content>
                        <Image
                            circular
                            floated='right'
                            size='tiny'
                            src={std.profile_image}
                            className='contain'
                        />
                        <Card.Header as={'p'}>{std.last_name + " " + std.other_names}</Card.Header>
                        <Card.Meta>DOB: {std.date_of_birth}</Card.Meta>
                        <Card.Description>
                            Joined: {std.date_registered}
                        </Card.Description>
                    </Card.Content>
                </Card>

                ))}

            </Card.Group>
            </Segment>
        </div>
        </React.Fragment>
    )
}