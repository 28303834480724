import React from 'react'
import { Comment, Segment, Header, Icon, Divider } from 'semantic-ui-react'
import {db} from '../db'

export default function News() {
    
    const [news, setNews] = React.useState([])
    React.useEffect(() => {
        db.collection('messages').onSnapshot(qss => {
            let ns = []
            qss.forEach(doc => {
                if(doc.data().to === 'All Parents'){
                    ns.push({...doc.data()})
                }
            })

            setNews(ns); ns = []
        })
    },[])

    return (

        <div className="inner-nodes">
            <Header content="Announcements" subheader="W. R. I. S" className="headers" />

            <Segment raised className="padded">
            <Comment.Group>
                {news.map((nc, idx)=> (
                        <Comment key={idx}>
                        <Comment.Avatar as='a' src='https://react.semantic-ui.com/images/avatar/small/joe.jpg' />
                        <Comment.Content>
                            <Comment.Author>Administrator</Comment.Author>
                            <Comment.Metadata>
                                <div>{nc.date_sent}</div>
                            </Comment.Metadata>
                            <Comment.Text>
                                {nc.message}
                            </Comment.Text>
                            <Comment.Actions>
                                <Comment.Action>
                                    <Icon name='checkmark' />
                                    Mark as Seen
                                </Comment.Action>
                            </Comment.Actions>
                        </Comment.Content>
                        <Divider />
                    </Comment>
                ))}
                
            </Comment.Group>
            </Segment>
        </div>

    )
}