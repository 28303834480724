import React, { useState } from "react";
import { Table, Header, Icon, Segment, List, Button } from "semantic-ui-react";
import { FeeDetails } from "./sub-components";
import _ from "lodash";

export default function Fees({ feesInfo, sett, student, bills }) {
  const [open, setOpen] = useState(false);
  const [cRec, setReceipt] = useState({});

  const getFeesDue = () => {
    let category = student.class_category;
    if (category !== undefined) {
      if (category === "pre_school" || category === "basic") {
        return sett.pre_school_fees;
      } else if (category === "lower_primary") {
        return sett.lower_primary_fees;
      } else if (category === "upper_primary") {
        return sett.upper_primary_fees;
      } else if (category === "junior_high") {
        return sett.junior_high_fees;
      }
    }
  };

  const getThisTermFees = () => {
    let fhistory = _.filter(feesInfo, (pd) => {
      return (
        (pd.term_paid === sett.current_term ||
          pd.term_paid === sett.current_semester) &&
        new Date(pd.date_paid).getFullYear() === new Date().getFullYear()
      );
    });

    if (fhistory.length === [].length) {
      return [0.0, getFeesDue()];
    } else {
      let amount_paid = _.sumBy(fhistory, (std) => {
        return parseFloat(std.amount_paid);
      });

      let balance = getFeesDue() - amount_paid;

      return [amount_paid, balance];
    }
  };

  const getBillFile = (cls) => {
    let term =
      cls === "junior_high" ? sett.current_semester : sett.current_term;

    let status = _.find(bills, (bd) => bd.term === term);

    return status === undefined ? false : status.bill_file;
  };

  const paymentThisTerm = getThisTermFees();
  return (
    <React.Fragment>
      <div className="inner-nodes">
        <FeeDetails open={open} fees={cRec} setOpen={setOpen} />
        <Header
          content="Fee Payments"
          subheader={sett.academic_year + " - Academic Year"}
          className="headers"
        />
        <Segment raised className="padded">
          <Segment raised>
            <List relaxed divided verticalAlign="middle">
              <List.Item>
                <List.Icon name="sort numeric up" size="large" color="green" />
                <List.Content>
                  <List.Header as="a">
                    Amount Paid: {paymentThisTerm[0]}
                  </List.Header>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name="sort numeric down" size="large" color="red" />
                <List.Content>
                  <List.Header as="a">
                    Arrears: {paymentThisTerm[1]}
                  </List.Header>
                </List.Content>
              </List.Item>

              <List.Item>
                <List.Icon name="map pin" size="large" color="orange" />
                <List.Content>
                  <List.Header as="a">
                    Term:{" "}
                    {student.class_category === "junior_high"
                      ? sett.current_semester
                      : sett.current_term}
                  </List.Header>
                </List.Content>
              </List.Item>
            </List>
          </Segment>

          <Segment raised>
            <Table singleLine celled unstackable selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan="3">
                    Payment History
                  </Table.HeaderCell>
                </Table.Row>
                <Table.Row>
                  <Table.HeaderCell>Date</Table.HeaderCell>
                  <Table.HeaderCell>Amount</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {feesInfo.length === 0 ? (
                  <Table.Row>
                    <Table.Cell colSpan="3" textAlign="center">
                      No Fees Records
                    </Table.Cell>
                  </Table.Row>
                ) : (
                  _.sortBy(feesInfo, (fh) => new Date(fh.date_paid).getTime())
                    .reverse()
                    .map((fd) => (
                      <Table.Row
                        key={fd.receipt_id}
                        onClick={() => {
                          setReceipt(fd);
                          setOpen(true);
                        }}
                      >
                        <Table.Cell>
                          {new Date(fd.date_paid).toDateString()}
                        </Table.Cell>
                        <Table.Cell>{fd.amount_paid}</Table.Cell>
                        <Table.Cell>
                          <Icon name="checkmark" color="green" />
                        </Table.Cell>
                      </Table.Row>
                    ))
                )}
              </Table.Body>
            </Table>
          </Segment>
          {getBillFile(student.class_category) === false ? null : (
            <Button
              as="a"
              target="_blank"
              rel="noreferrer"
              href={getBillFile(student.class_category)}
              color="teal"
              size="mini"
            >
              {" "}
              <Icon name="download" /> Download Bill
            </Button>
          )}
        </Segment>
      </div>
    </React.Fragment>
  );
}
