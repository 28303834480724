import React from 'react'
import { Card, Image, Button, Icon, Grid, Header } from 'semantic-ui-react'
import _ from 'lodash'
export default function StaffProfile({ staff, profile }) {
    //get students class category
    let category = profile.class_category
    let current_class = profile.class_admitted

    const getStudentStaff = () => {
        if (category === 'pre_school') {
            
            return _.filter(staff, (std) => std.class_assigned === current_class || std.class_range === 'Pre School')
        } else if (category === 'lower_primary') {
            
            return _.filter(staff, (std) => std.class_assigned === current_class || std.class_range === 'Lower Primary' || std.class_range === 'Lower and Upper' || std.class_range === 'Lower to Junior High')

        } else if (category === 'upper_primary') {
            return _.filter(staff, (std) => std.class_assigned === current_class || std.class_range === 'Upper Primary' || std.class_range === 'Upper and Junior High')
        } else if (category === 'junior_high') {
            return _.filter(staff, (std) => std.class_assigned === current_class || std.class_range === 'Junior High' || std.class_range === 'Upper and Junior High' || std.class_range === 'Lower to Junior High')

        }
    }
    return (
        <React.Fragment>
            <Header className="headers" content="Staff Profile" subheader="Teachers teaching your Ward" />
            <Grid padded columns={1} centered>
                <Grid.Column>
                    <Card.Group centered>
                        {getStudentStaff().map(std => (
                            <Card raised key={std.staff_id} centered>
                                <Card.Content>
                                    <Image
                                        circular
                                        floated='right'
                                        size='tiny'
                                        src={std.profile_image}
                                    />
                                    <Card.Header>{std.last_name + " " + std.first_name}</Card.Header>
                                    <Card.Meta>Subject: {std.subject_assigned}</Card.Meta>
                                    <Card.Description>
                                        Phone: {std.phone}
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content extra>
                                    <Button color='green' circular size="mini" >
                                        <Icon name="phone" flipped="horizontally" /> <a href={"tel:" + std.phone} rel="noopener noreferrer">Call</a>
                                    </Button>
                                </Card.Content>
                            </Card>
                        ))}

                    </Card.Group>
                </Grid.Column>
            </Grid>
        </React.Fragment>
    )
}