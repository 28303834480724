var pdfMake = require('pdfmake/build/pdfmake.js');
var pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var { rcover} = require('./images');

export default function generate_receipt(fss){

	var doc_deff = {
		paperSize:"A5",
		pageOrientation:"landscape",
		  watermark: { text: 'W.R.I.S', color: 'lightgrey', opacity: 0.5, bold: true, italics: true},
		content: [
			{
				image:rcover,
				width:845,
				absolutePosition:{x:0,y:0}
			},
			{
				text:fss.last_name.toUpperCase() + " " + fss.other_names.toUpperCase(),
				style:{fontSize:16},
				absolutePosition:{x:305, y:218}
			},
			{
				text:fss.term_paid.toUpperCase(),
				style:{fontSize:16},
				absolutePosition:{x:200, y:272}
			},
			 {
				text:fss.student_id.toUpperCase(),
				style:{fontSize:16},
				absolutePosition:{x:650, y:270}
			},
			 {
				text:fss.receipt_id.toUpperCase(),
				style:{fontSize:16},
				absolutePosition:{x:239, y:326}
			},
			 {
				text:fss.class_admitted.toUpperCase(),
				style:{fontSize:16},
				absolutePosition:{x:600, y:326}
			},
		 
			 {
				text:fss.date_paid,
				style:{fontSize:16},
				absolutePosition:{x:235, y:388}
			},
			 {
				text:fss.amount_paid,//AMOUNTPAID
				style:{fontSize:16},
				absolutePosition:{x:707, y:388}
			},
			{
				text:fss.balance,//BALANCE
				style:{fontSize:16},
				absolutePosition:{x:660, y:445}
			},
		 
		]
		
	}

return (
	pdfMake.createPdf(doc_deff).download('WRIS-Receipt-'+fss.receipt_id)
)
}