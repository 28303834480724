import React from 'react'
import {Grid, Header, Form, Segment,Table} from 'semantic-ui-react'
import {days, weeks} from './sub-components'
import _ from 'lodash'
export default class Records extends React.Component{

        constructor(){
            super();
            this.state = {
                week_selected:'N/A',
                day_selected:'N/A',
                record_data:[]
            }
        }

    getRecordData = () => {

        const {profile, records, sett} = this.props
        let rcd = _.filter(records, (rd) => {return (rd.term === sett.current_term || rd.term === sett.current_semester) } )
        let all_data = _.sortBy(rcd, (rd) => new Date(rd.date_processed).getTime())

        let record_data = []
        for (var d in all_data) {

            for (var dt of all_data[d].data) {
                if (dt.student_id === profile.student_id.toUpperCase()) {
                    record_data.push({ ...dt, term: all_data[d].term, date: all_data[d].date_processed, subject: all_data[d].subject, week: all_data[d].week, day: all_data[d].day })
                }
            }

        }

        return record_data; 
    }

    filterData  = () => {

        return _.filter(this.getRecordData(), (rd) => rd.week === this.state.week_selected && rd.day === this.state.day_selected)
    }

    render(){
            const records = this.filterData()

        return(
            <div className="inner-nodes">
                <Header content="Daily Records" subheader="Exercises / Assignments & More " className="headers"/>
                <Grid stackable centered>
                <Grid.Column  mobile={16} computer={10} tablet={16}>
                    <Segment raised>
                        <Form>
                            <Form.Select value={this.state.week_selected} label="Select Week" options={weeks} onChange={(e, target) => this.setState({week_selected:target.value})} /> 
                            <Form.Select value={this.state.day_selected} label="Select Day" options={days} onChange={(e, target) => this.setState({day_selected:target.value})} />
                        </Form>
                        {records.length === 0 ? 
                        <Header textAlign="center" content="Data not Available" subheader="Select week and day to check!" /> : 
                        <Table unstackable celled singleLine fixed>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell colSpan="3" textAlign="left">{this.state.week_selected}</Table.HeaderCell>
                                    <Table.HeaderCell colSpan="2" textAlign="right">{this.state.day_selected}</Table.HeaderCell>
                                </Table.Row>
                                <Table.Row textAlign="center">
                                    <Table.HeaderCell>Course</Table.HeaderCell>
                                    <Table.HeaderCell>C.E</Table.HeaderCell>
                                    <Table.HeaderCell>H.W</Table.HeaderCell>
                                    <Table.HeaderCell>C.T</Table.HeaderCell>
                                    <Table.HeaderCell>P.W</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {records.map((rcd, idx) => (
                                    <Table.Row textAlign="center" key={idx}>
                                    <Table.Cell>{rcd.subject}</Table.Cell>
                                    <Table.Cell>{rcd.ex_score === 0 ? "N/A" : rcd.ex_score}</Table.Cell>
                                    <Table.Cell>{rcd.as_score === 0 ? "N/A" : rcd.as_score}</Table.Cell>
                                    <Table.Cell>{rcd.ct_score === 0 ? "N/A" : rcd.ct_score}</Table.Cell>
                                    <Table.Cell>{rcd.pw_score === 0 ? "N/A" : rcd.pw_score}</Table.Cell>
                                </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>} 
                    </Segment>
                </Grid.Column>
                </Grid>
            </div>
        )
    }
}