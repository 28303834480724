import _ from 'lodash'
import generate_report from '../components/sub-components/generates/generate-report';
import generate_pre_report from '../components/sub-components/generates/generate-pre-report';

export const getImage = (url, exam) =>
fetch(url)
  .then((response) => response.blob())
  .then((blob) => {
    const reader = new FileReader();
    reader.onload = () => {
      exam.profile_image = reader.result;
      // this.setState({ data_image: reader.result });
    };
    reader.onerror = (err) => {
      console.log(err);
    };
    reader.readAsDataURL(blob);
  })
  .catch((err) => {
    console.log("Error: ", err);
  });


 const sortResults = (arr) => {
    return arr
      .sort((a, b) => {
        return a - b;
      })
      .reverse();
  };

  export const generate_exams = (reports, report, category, method) => {

      let jhs = {},
        ups = {},
        pps = {};

      let math_scores = [],
        eng_scores = [],
        sci_scores = [],
        soc_scores = [],
        fantse_scores = [],
        rme_scores = [],
        bdt_scores = [],
        french_scores = [],
        ict_scores = [],
        owop_scores = [],
        history_scores = [],
        cr_scores = [],
        overrall_aggs = [],
        ph_scores = [],
        ctech_scores = [],
        writ_scores = [];

      if (reports.length > 0) {
        //separation of courses by class_category
        //junior High Courses.
        if (category === "junior_high") {
          _.map(reports, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            sci_scores.push(cp.sts);
            soc_scores.push(cp.ssts);
            fantse_scores.push(cp.fnts);
            rme_scores.push(cp.rmts);
            french_scores.push(cp.fts);
            ict_scores.push(cp.ictts);
            bdt_scores.push(cp.bdts);
            ph_scores.push(cp.phts);
            ctech_scores.push(cp.ctts);
            cr_scores.push(cp.crats);
            history_scores.push(cp.hts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        } else if (
          category === "upper_primary" ||
          category === "lower_primary"
        ) {
          _.map(reports, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            sci_scores.push(cp.sts);
            fantse_scores.push(cp.fnts);
            rme_scores.push(cp.rmts);
            french_scores.push(cp.fts);
            ict_scores.push(cp.ictts);
            owop_scores.push(cp.owts);
            cr_scores.push(cp.crats);
            history_scores.push(cp.hts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        } else if (category === "pre_school") {
          _.map(reports, (cp) => {
            math_scores.push(cp.mts);
            eng_scores.push(cp.ets);
            writ_scores.push(cp.wrts);
            owop_scores.push(cp.owts);
            cr_scores.push(cp.crats);
            ph_scores.push(cp.phts);
            overrall_aggs.push(cp.overrall_aggregate);
          });
        }

        jhs = {
          maths: sortResults(math_scores),
          science: sortResults(sci_scores),
          english: sortResults(eng_scores),
          social: sortResults(soc_scores),
          rme: sortResults(rme_scores),
          french: sortResults(french_scores),
          fantse: sortResults(fantse_scores),
          ict: sortResults(ict_scores),
          bdt: sortResults(bdt_scores),
          history: sortResults(history_scores),
          c_tech: sortResults(ctech_scores),
          pandh: sortResults(ph_scores),
          creative_arts: sortResults(cr_scores),
          overrall: sortResults(overrall_aggs),
        };

        ups = {
          maths: sortResults(math_scores),
          creative_arts: sortResults(cr_scores),
          science: sortResults(sci_scores),
          english: sortResults(eng_scores),
          rme: sortResults(rme_scores),
          french: sortResults(french_scores),
          fantse: sortResults(fantse_scores),
          ict: sortResults(ict_scores),
          owop: sortResults(owop_scores),
          history: sortResults(history_scores),
          overrall: sortResults(overrall_aggs),
        };

        pps = {
          maths: sortResults(math_scores),
          creative_arts: sortResults(cr_scores),
          writing: sortResults(writ_scores),
          phonics: sortResults(ph_scores),
          owop: sortResults(owop_scores),
          overrall: sortResults(overrall_aggs),
          english: sortResults(eng_scores),
        };

        // this.getProfileImage(this.state.student.profile_image) Tried all I can but null

        switch (category) {
          case "lower_primary":
          case "upper_primary":
            generate_report(
              ups,
              report,
              method
            );
            break;
          case "junior_high":
            generate_report(
              jhs,
              report,
              method
            );
            break;
          case "pre_school":
            generate_pre_report(
              pps,
              report,
              method
            );
            break;
          default:
            console.log("wont reach here!");
        }
      } else {
        console.log("not -reports");
      }

  };
