import React from 'react'
import { Menu, Header, Button, Confirm, Icon} from 'semantic-ui-react'
import {auth} from '../../../db'
import logo from '../../../images/logo.png'

export default function TopNav({setPage}) {
    const [open , setOpen] = React.useState(false)
    const id = localStorage.getItem("StudentID")

    return (

        <Menu fixed="top" className='sticky' borderless>
            <Confirm onConfirm={() => auth().signOut()} cancelButton={<Button circular size="mini" color="red">No, Stay</Button>} confirmButton={<Button circular size="mini" color="green">Log Out</Button>} open={open} onCancel={() => setOpen(false)} closeOnDimmerClick={false} header="Logging Out" content="This action will log you out of the portal. Continue ?" />
            <Menu.Item onClick={() => setPage('dashboard')}>
             <Header as='h3' image={{src:logo, size:'tiny'}} content='W R I S - Portal' subheader={"Welcome, " + id}/>
            </Menu.Item>

            <Menu.Menu position="right">
                <Menu.Item as='a' onClick={() => setOpen(true)}>
                    <Icon name="log out" color="red" circular  />
                </Menu.Item>
            </Menu.Menu>
        </Menu>

    )
}
