import React from 'react'
import {Header, Table, Segment} from 'semantic-ui-react'

export default function Calendar({cal}){

    return(
        <div className="inner-nodes">
                <Header content="Academic Calendar" subheader="Academic Year" className="headers"/>
                <Segment raised className="padded">
                <Table celled unstackable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Activity</Table.HeaderCell>
                            <Table.HeaderCell>Duration</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Reopening</Table.Cell>
                            <Table.Cell>{cal.reopening}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Mid Term Exams</Table.Cell>
                            <Table.Cell>{cal.mid_term_exams}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>End of Term Exams</Table.Cell>
                            <Table.Cell>{cal.examination}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Holidays</Table.Cell>
                            <Table.Cell>{cal.holidays}</Table.Cell>
                        </Table.Row>

                       
                        <Table.Row>
                            <Table.Cell>Naps Week</Table.Cell>
                            <Table.Cell>{cal.naps_week}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                            <Table.Cell>Others</Table.Cell>
                            <Table.Cell>{cal.others}</Table.Cell>
                        </Table.Row>
                        
                    </Table.Body>
                </Table>
                </Segment>
        </div>
    )
}