import React from "react";
import { Segment, Form, Button, Icon, Image, Header } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import logo from "../../images/logo.png";
import { db, auth } from "../../db";
import _ from "lodash";

export default function Login() {
  const [parentsData, setParentData] = React.useState([]);
  const [student_id, setID] = React.useState("");
  const [password, setPassword] = React.useState("");

  React.useEffect(() => {
    let data = [];
    db.collection("student_registration")
      .get()
      .then((qss) => {
        qss.forEach((std) => {
          data.push({ ...std.data(), _id: std.id });
        });
        setParentData(data);
        data = [];
      });
  }, []);

  const loginToApp = (event) => {
    event.preventDefault();
    let is_permitted = _.find(parentsData, (pd) => {
      return pd.student_id === student_id;
    });
    if (is_permitted === undefined) {
      alert("Data not Available");
    } else {
      let new_mail = is_permitted.student_id.trim() + "@wrisgh.com";
      auth()
        .signInWithEmailAndPassword(new_mail, password)
        .then((resp) => {
            localStorage.setItem("StudentID", student_id);
          <Redirect to="/" />;
        })
        .catch((err) => {
          alert(err.message);
        });
    }
  };
  return (
    <Segment className="form-container">
      <Image size="small" circular src={logo} alt="WRIS LOGO" />
      <Header
        content="Parent's Portal"
        inverted
        subheader="W R I S"
        textAlign="center"
        as="h1"
      />
      <Segment raised className="form-content">
        <Form onSubmit={loginToApp}>
          <Form.Input
            minLength="12"
            maxLength="12"
            label="Student ID"
            required
            onChange={(e) => setID(e.target.value)}
            value={student_id}
          />
          <Form.Input
            label="Password"
            type="password"
            required
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <Button
            color="red"
            icon
            disabled={!student_id || !password}
          >
            Log in <Icon name="send" />
          </Button>
        </Form>
      </Segment>
      <p style={{ color: "#ccc" }}>
        Not Registered Yet ?{" "}
        <Link to="/register" className="out-link">
          Register{" "}
        </Link>
      </p>
      <p style={{ color: "#ccc" }}>
        Powered by{" "}
        <a
          style={{ color: "#fff" }}
          target="_blank"
          rel="noreferrer"
          href="https://labs.logickidsgh.com"
        >
          &copy; Logic Labs
        </a>
      </p>
    </Segment>
  );
}
