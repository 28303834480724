import React from 'react'
import { List, Header, Segment, Card, Icon} from 'semantic-ui-react'
import _ from 'lodash'

export default function Assignments({profile, sett, assign}) {
    const [selectedA, setSelected] = React.useState(null)
    const assignData = () => {
        return _.filter(assign,(asd) => {return asd.class_target === profile.class_admitted && asd.authorized === true})
    }
    let term = profile.class_category === 'junior_high' ? sett.current_semester : sett.current_term
    return (

        <div className="inner-nodes">
            <Header content="Class Assignments" subheader={profile.class_admitted + "/" +  term} className="headers" />
            <Segment raised className="padded">
                <List animated relaxed divided>
                    {assignData().map(idx => (
                        <List.Item key={idx._id} onClick={() => setSelected(idx)}>
                        <List.Icon name='edit' size='large' verticalAlign='middle' />
                        <List.Content>
                            <List.Header >{idx.subject}</List.Header>
                            <List.Description as='a'>Due {idx.date_due} &nbsp; &nbsp; Posted: {idx.date_sent}</List.Description>
                        </List.Content>
                    </List.Item>
                    ))}
                    
                </List>
                {selectedA === null ? 
                null: 
                <Card centered raised>
                    <Card.Content header={selectedA.subject} meta={`Uploaded on: ${selectedA.date_sent}`}/>
                    <Card.Content description={<><br/> <div dangerouslySetInnerHTML={{__html:selectedA.content}} /></>} /> 
                    <Card.Content extra>
                        <p>Date Due: {selectedA.date_due} &nbsp; &nbsp; &nbsp; 
                        {selectedA.file_link === undefined ? null : <a target="_blank" rel="noopener noreferrer" href={selectedA.file_link}><Icon name="download" color="red"/></a> }
                        </p>
                    </Card.Content>
                </Card>}
            </Segment>
        </div>
    )
}