import { wris_header, signature, scale } from "./images";
import _ from "lodash";
var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function generate_pre_report(
  ccreport,
  csrp,
  method
) {
  const divider = parseInt(csrp.n_courses);

  const getCoursePosition = (total_score, all_scores) => {
    const uniqueScores = _.uniq(all_scores);
    const grade_position = parseInt(uniqueScores.indexOf(total_score) + 1);
    //getting grade with index values
    if (grade_position.toString().endsWith("1") && grade_position !== 11) {
      return grade_position.toString() + "st";
    } else if (
      grade_position.toString().endsWith("2") &&
      grade_position !== 12
    ) {
      return grade_position.toString() + "nd";
    } else if (
      grade_position.toString().endsWith("3") &&
      grade_position !== 13
    ) {
      return grade_position.toString() + "rd";
    } else {
      return grade_position.toString() + "th";
    }
  };

  const doc_deff = {
    paperSize: "A4",
    watermark: {
      text: "WRIS",
      color: "lightgrey",
      opacity: 0.4,
      bold: true,
      italics: true,
    },
    content: [
      {
        image: wris_header,
        width: 595,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        image: csrp.profile_image,
        width: 95,
        absolutePosition: { x: 485, y: 68 },
      },
      {
        text: "Term: " + csrp.term,
        absolutePosition: { x: 20, y: 160 },
      },
      {
        text: "Class: " + csrp.class_admitted,
        absolutePosition: { x: 20, y: 190 },
      },
      {
        text:
          "Position: " +
          getCoursePosition(csrp.overrall_aggregate, ccreport.overrall),
        absolutePosition: { x: 320, y: 160 },
      },
      {
        text: "Next Term Begins: " + csrp.next_term,
        absolutePosition: { x: 320, y: 190 },
      },
      {
        text: "Academic Year: " + csrp.academic_year,
        absolutePosition: { x: 20, y: 220 },
      },
      {
        text: "Overall Score: " + csrp.overrall_aggregate,
        absolutePosition: { x: 320, y: 220 },
      },
      {
        text:
          "Average Score: " +
          Number(csrp.overrall_aggregate / divider).toFixed(2),
        absolutePosition: { x: 20, y: 250 },
      },
      {
        text: "No on Roll: " + csrp.num_on_roll,
        absolutePosition: { x: 320, y: 250 },
      },
      {
        text: "Name: " + csrp.last_name + "  " + csrp.other_names,
        absolutePosition: { x: 20, y: 130 },
      },
      {
        absolutePosition: { x: 20, y: 290 },
        style:{fontSize:10},
        table: {
          widths: [100, 60, 70, 60, 40, 70, 100],
          heights: 20,
          body: [
            [
              { text: "COURSES", alignment: "center", bold: true },
              { text: "CLASS\nSCORE", alignment: "center", bold: true },
              { text: "EXAMS\nSCORE", alignment: "center", bold: true },
              { text: "TOTAL\nSCORE", alignment: "center", bold: true },
              { text: "GRADE", alignment: "center", bold: true },
              { text: "CLASS\nPOSITION", alignment: "center", bold: true },
              { text: "REMARKS", alignment: "center", bold: true },
            ],
            [
              { text: "Numeracy", bold: true },
              { text: csrp.mcs * 0.5, alignment: "center" },
              { text: csrp.mes * 0.5, alignment: "center" },
              { text: csrp.mts, alignment: "center" },
              { text: csrp.mgr, alignment: "center" },
              {
                text: getCoursePosition(csrp.mts, ccreport.maths),
                alignment: "center",
              },

              { text: csrp.mrm, alignment: "center" },
            ],
            [
              { text: "Writing", bold: true },
              { text: csrp.wrcs * 0.5, alignment: "center" },
              { text: csrp.wres * 0.5, alignment: "center" },
              { text: csrp.wrts, alignment: "center" },
              { text: csrp.wrgr, alignment: "center" },
              {
                text: getCoursePosition(csrp.wrts, ccreport.writing),
                alignment: "center",
              },

              { text: csrp.wrrm, alignment: "center" },
            ],
            [
              { text: "Language & Lit", bold: true },
              { text: csrp.ecs * 0.5, alignment: "center" },
              { text: csrp.ees * 0.5, alignment: "center" },
              { text: csrp.ets, alignment: "center" },
              { text: csrp.egr, alignment: "center" },
              {
                text: getCoursePosition(csrp.ets, ccreport.english),
                alignment: "center",
              },

              { text: csrp.erm, alignment: "center" },
            ],

            [
              { text: "O W O P", bold: true },
              { text: csrp.owcs * 0.5, alignment: "center" },
              { text: csrp.owes * 0.5, alignment: "center" },
              { text: csrp.owts, alignment: "center" },
              { text: csrp.owgr, alignment: "center" },
              {
                text: getCoursePosition(csrp.owts, ccreport.owop),
                alignment: "center",
              },

              { text: csrp.owrm, alignment: "center" },
            ],
            [
              { text: "Phonics", bold: true },
              { text: csrp.phcs * 0.5, alignment: "center" },
              { text: csrp.phes * 0.5, alignment: "center" },
              { text: csrp.phts, alignment: "center" },
              { text: csrp.phgr, alignment: "center" },
              {
                text: getCoursePosition(csrp.phts, ccreport.phonics),
                alignment: "center",
              },

              { text: csrp.phrm, alignment: "center" },
            ],

            [
              { text: "Creative Arts", bold: true },
              { text: csrp.cracs * 0.5, alignment: "center" },
              { text: csrp.craes * 0.5, alignment: "center" },
              { text: csrp.crats, alignment: "center" },
              { text: csrp.cragr, alignment: "center" },
              {
                text: getCoursePosition(csrp.crats, ccreport.creative_arts),
                alignment: "center",
              },

              { text: csrp.crarm, alignment: "center" },
            ],
          ],
        },
      },
			{
				image:scale, 
				width:250, 
				absolutePosition:{x:335, y:570}
			},
      {
        text:
          "Attendance: " +
          csrp.attendance +
          "          Out of : " +
          csrp.total_days +
          "                       Promoted to: " + csrp.promoted_to || "_____",
        absolutePosition: { x: 20, y: 620 },
      },
      {
        text:
          "Attitude: " +
          csrp.attitude +
          "                                                        Interest: " +
          csrp.interest,
        absolutePosition: { x: 20, y: 650 },
      },
      {
        text: "Conduct: " + csrp.conduct,
        absolutePosition: { x: 20, y: 680 },
      },
      {
        text: "Class Teacher's Remarks: " + csrp.remarks,
        absolutePosition: { x: 20, y: 710 },
      },
      {
        text: "Head Teacher's Remarks: " + csrp.ht_remarks,
        absolutePosition: { x: 20, y: 740 },
      },
      {
        text: "...........................................\nClass Teacher's Signature",
        absolutePosition: { x: 20, y: 770 },
      },
      {
				image:signature, 
				width:80,
				absolutePosition:{x:400, y:750}
			},
      {
        text: "...........................................\nHead Teacher's Signature",
        absolutePosition: { x: 400, y: 770 },
      },
    ],
  };

  if (method === "print") {
    return pdfMake.createPdf(doc_deff).print();
  } else {
    return pdfMake
      .createPdf(doc_deff)
      .download(csrp.last_name + " " + csrp.other_names + "-" + csrp.term);
  }
}
