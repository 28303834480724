import React from 'react'
import {Segment, Header, Image, Table} from 'semantic-ui-react'


export default function Profile({profileInfo}){

    return(
        <React.Fragment>
        <div className="inner-nodes">
            <Header content="Profile Details" subheader={profileInfo.last_name + " " + profileInfo.other_names} className="headers" />
            <Segment raised className="padded">
                <Image src={profileInfo.profile_image} size="small" circular centered className='contain'/> 
                <Header content={profileInfo.last_name + " " + profileInfo.other_names} subheader={profileInfo.class_admitted} textAlign="center"/>
            </Segment>
            <br/> <br/>
            <Segment raised className="padded">
                <Header content="Personal Details" />
           
                    <Table striped unstackable >
                        <Table.Body>

                            <Table.Row>
                                <Table.Cell>Student ID</Table.Cell>
                                <Table.Cell>{profileInfo.student_id}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Surname</Table.Cell>
                                <Table.Cell>{profileInfo.last_name}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Other Names</Table.Cell>
                                <Table.Cell>{profileInfo.other_names}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Gender</Table.Cell>
                                <Table.Cell>{profileInfo.gender}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Class</Table.Cell>
                                <Table.Cell>{profileInfo.class_admitted}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Date of Birth</Table.Cell>
                                <Table.Cell>{profileInfo.date_of_birth}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Religion</Table.Cell>
                                <Table.Cell>{profileInfo.religion}</Table.Cell>
                            </Table.Row>

                             <Table.Row>
                                <Table.Cell>Home Town</Table.Cell>
                                <Table.Cell>{profileInfo.home_town}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Former School</Table.Cell>
                                <Table.Cell>{profileInfo.former_school}</Table.Cell>
                            </Table.Row>


                        </Table.Body>
                    </Table>
                

                <Header content="Parents Details" />
           
                    <Table striped  unstackable>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Mother</Table.Cell>
                                <Table.Cell>{profileInfo.name_of_mom}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Phone</Table.Cell>
                                <Table.Cell>{profileInfo.phone_of_mom}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Occupation</Table.Cell>
                                <Table.Cell>{profileInfo.m_occupation}</Table.Cell>
                            </Table.Row>

                             <Table.Row>
                                <Table.Cell>Father</Table.Cell>
                                <Table.Cell>{profileInfo.name_of_dad}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Phone</Table.Cell>
                                <Table.Cell>{profileInfo.guardian_phone}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Occupation</Table.Cell>
                                <Table.Cell>{profileInfo.f_occupation}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                                <Table.Cell>Address</Table.Cell>
                                <Table.Cell>{profileInfo.digital_addr}</Table.Cell>
                            </Table.Row>

                            

                        </Table.Body>
                    </Table>
                
            </Segment>
        </div>
        </React.Fragment>
    )
}