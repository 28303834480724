export const terms = [
    {
        text: "First Term",
        value: "First Term"
    },
    {
        text: "Second Term",
        value: "Second Term"
    },
    {
        text: "Third Term",
        value: "Third Term"
    }
]

export const semesters = [
    {
        text: "First Semester",
        value: "First Semester"
    },
    {
        text: "Second Semester",
        value: "Second Semester"
    }
]

export const days = [
    {
        text:"Sunday",
        value:"Sunday",
        disabled:true
    },
    {
        text: "Monday",
        value: "Monday"
    },
    {
        text: "Tuesday",
        value: "Tuesday"
    },
    {
        text: "Wednesday",
        value: "Wednesday"
    },
    {
        text: "Thursday",
        value: "Thursday"
    },
    {
        text: "Friday",
        value: "Friday"
    },
    {
        text:"Saturday",
        value:"Saturday",
        disabled:true
    }
]

export const years = [
    {
        text:2021,
        value:2021
    },
    {
        text:2022,
        value:2022,
        disabled: new Date().getFullYear() < 2022
    },
    {
        text:2023,
        value:2023,
        disabled: new Date().getFullYear() < 2023
    },
    {
        text:2024,
        value:2024,
        disabled: new Date().getFullYear() < 2024
    },
    {
        text:2025,
        value:2025,
        disabled: new Date().getFullYear() < 2025
    }
]

export const weeks = [
    {
        text: 'Week 1',
        value: 'Week 1'
    },
    {
        text: 'Week 2',
        value: 'Week 2'
    },
    {
        text: 'Week 3',
        value: 'Week 3'
    },
    {
        text: 'Week 4',
        value: 'Week 4'
    },
    {
        text: 'Week 5',
        value: 'Week 5'
    },
    {
        text: 'Week 6',
        value: 'Week 6'
    },
    {
        text: 'Week 7',
        value: 'Week 7'
    },
    {
        text: 'Week 8',
        value: 'Week 8'
    },
    {
        text: 'Week 9',
        value: 'Week 9'
    },
    {
        text: 'Week 10',
        value: 'Week 10'
    },
    {
        text: 'Week 11',
        value: 'Week 11'
    },
    {
        text: 'Week 12',
        value: 'Week 12'
    },
    {
        text: 'Week 13',
        value: 'Week 13'
    },
    {
        text: 'Week 14',
        value: 'Week 14'
    },
    {
        text: 'Week 15',
        value: 'Week 15'
    },
    {
        text: 'Week 16',
        value: 'Week 16'
    }
]