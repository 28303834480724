import React, { useState, useEffect } from "react";
import BottomNav from "./bottom-nav";
import TopNav from "./top-nav";
import { db, auth } from "../../../db";
import _ from "lodash";

import {
  Fees,
  StaffProfile,
  Classmates,
  Profile,
  Attendance,
  News,
  Records,
  Report,
  Calendar,
  Assignments,
  TimeTable,
} from "../../../components";

export default function MobileApp() {
  const [nPage, setNewPage] = useState("dashboard");

  let cUser = auth().currentUser.email.slice(0, 12).toUpperCase();

  const [settings, setSettings] = useState({});
  const [aCalendar, setCalendar] = useState({});
  const [studInfo, setStudentData] = useState([]);
  const [feesData, setFeesData] = useState([]);
  const [attData, setAttData] = useState([]);
  const [staffData, setStaffData] = useState([]);
  const [tables, setTables] = useState([]);
  const [assign, setAssign] = useState([]);
  const [tfiles, setTfile] = useState([]);
  const [bfiles, setBFiles] = useState([]);
  const [release, setRelease] = useState([]);
  const [records, setRecords] = useState([]);

  useEffect(() => {
    //settings.
    db.collection("app_settings").onSnapshot((qss) => {
      qss.forEach((doc) => {
        if (doc.id === "sch_settings") {
          setSettings({ ...doc.data() });
        }

        if (doc.id === "academic_calendar") {
          setCalendar({ ...doc.data() });
        }
      });
    });

    db.collection("file_docs")
      .get()
      .then((qss) => {
        let doc1 = [];
        qss.forEach((doc) => {
          doc1.push({ ...doc.data(), _id: doc.id });
        });
        setTfile(doc1);
        doc1 = [];
      });

    db.collection("class_assignments").onSnapshot((qss) => {
      let assN = [];
      qss.forEach((doc) => {
        assN.push({ ...doc.data(), _id: doc.id });
      });
      setAssign(assN);
      assN = [];
    });

    //student data
    db.collection("student_registration").onSnapshot((qss) => {
      let sData = [];
      qss.forEach((doc) => {
        if (doc.data().status === undefined || doc.data().status === "active") {
          sData.push({ ...doc.data() });
        }
      });
      setStudentData(
        _.sortBy(sData, (sd) => {
          return sd.last_name;
        })
      );
      sData = [];
    });

    db.collection("bill_files")
      .get()
      .then((qss) => {
        let doc1 = [];
        qss.forEach((doc) => {
          doc1.push({ ...doc.data(), _id: doc.id });
        });

        setBFiles(doc1);
        doc1 = [];
      });

    // fees
    db.collection("fee_payment")
      .where("student_id", "==", cUser)
      .onSnapshot((qss) => {
        let fData = [];
        qss.forEach((doc) => {
          fData.push({ ...doc.data(), _id: doc.id });
        });

        setFeesData(fData);
        fData = [];
      });

    // attendance
    db.collection("attendance").onSnapshot((qss) => {
      let attD = [];
      qss.forEach((doc) => {
        if (doc.data().year === new Date().getFullYear()) {
          attD.push({ ...doc.data() });
        }
      });
      setAttData(attD);
      attD = [];
    });

    // exams releases
    db.collection("e_releases").onSnapshot((qss) => {
      let eR = [];
      qss.forEach((doc) => {
        eR.push({ ...doc.data(), _id: doc.id });
      });
      setRelease(eR);
      eR = [];
    });

    // staff profiles
    db.collection("staff_registration")
      .where("employee_category", "==", "Teacher")
      .onSnapshot((qss) => {
        let staff = [];
        qss.forEach((doc) => {
          if (
            (doc.data().status === undefined || doc.data().status === "active")
          ) {
            staff.push({ ...doc.data() });
          }
        });
        setStaffData(staff);
        staff = [];
      });


    //tables
    db.collection("time_tables").onSnapshot((qss) => {
      let tabl = [];
      qss.forEach((doc) => {
        tabl.push({ ...doc.data() });
      });

      setTables(tabl);
      tabl = [];
    });

    // daily Records
    db.collection("daily_records").onSnapshot((qss) => {
      let dR = [];
      qss.forEach((doc) => {
        dR.push({ ...doc.data(), _id: doc.id });
      });
      setRecords(dR);
      dR = [];
    });
  }, [cUser]);

  const profileInfo = _.find(studInfo, (sd) => {
    return sd.student_id === cUser;
  });
  const mainP = profileInfo === undefined ? {} : profileInfo;
  const fData = _.filter(feesData, (sd) => {
    return sd.student_id === cUser;
  });
  const mates = _.filter(studInfo, (sd) => {
    return sd.class_admitted === mainP.class_admitted;
  });
  const file = _.find(tfiles, (fd) => fd.class === mainP.class_admitted);
  const bills = _.filter(
    bfiles,
    (fd) =>
      fd.class_category === mainP.class_category &&
      new Date().getFullYear() === fd.year
  );
  const rcd = _.filter(
    records,
    (rd) =>
      rd.current_class === mainP.class_admitted &&
      new Date(rd.date_processed).getFullYear() === new Date().getFullYear()
  );
  let currentPage;
  switch (nPage) {
    case "dashboard":
      currentPage = (
        <Report
          release={release}
          sett={settings}
          profile={mainP}
          roll={mates.length}
        />
      );
      break;
    case "fees":
      currentPage = (
        <Fees bills={bills} feesInfo={fData} sett={settings} student={mainP} />
      );
      break;
    case "classmates":
      currentPage = <Classmates mates={mates} cClass={mainP.class_admitted} />;
      break;
    case "calendar":
      currentPage = <Calendar cal={aCalendar} />;
      break;
    case "profile":
      currentPage = <Profile profileInfo={mainP} />;
      break;
    case "assignment":
      currentPage = (
        <Assignments assign={assign} profile={mainP} sett={settings} />
      );
      break;
    case "time_tables":
      currentPage = <TimeTable file={file} table={tables} profile={mainP} />;
      break;
    case "records":
      currentPage = <Records records={rcd} sett={settings} profile={mainP} />;
      break;
    case "staff_profile":
      currentPage = <StaffProfile staff={staffData} profile={mainP} />;
      break;
    case "attendance":
      currentPage = (
        <Attendance attd={attData} sett={settings} profile={mainP} />
      );
      break;
    case "news":
      currentPage = <News />;
      break;
    default:
      currentPage = (
        <Report
          profile={mainP}
          sett={settings}
          roll={mates.length}
        />
      );
  }

  return (
    <>
      <TopNav setPage={setNewPage} />
      {currentPage}
      <BottomNav setPage={setNewPage} />
    </>
  );
}
