import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { PrivateRoute, PublicRoute } from '../../components/routes'
import { Login, Register } from '../../components'
import MobileApp from './navs/mobile-app'
import {auth} from '../../db'


export default class MobileNav extends React.Component{
  constructor(){
    super()
    this.state = {
      authenticated:false
    }
  }
 

  componentDidMount(){
    auth().onAuthStateChanged((user) => {
      if(user){
        this.setState({authenticated:true})
      }else{
        this.setState({authenticated:false})
      }})
  }
  render(){
        const {authenticated} = this.state
    return(
      <Router>
      <Switch>
        <PrivateRoute path="/" component={MobileApp} exact authenticated={authenticated}/>
        <PublicRoute path="/login" component={Login} exact authenticated={authenticated}/>
        <PublicRoute path="/register" component={Register} exact authenticated={authenticated}/>
      </Switch>
    </Router>
    )
  }
}

