import React, { useState } from "react";
import {
  Segment,
  Grid,
  Header,
  Form,
  Table,
  List,
  Button,
  Icon,
} from "semantic-ui-react";
import { generate_exams, getImage } from "../functions";
import { terms, years, semesters } from "./sub-components/calls";
import { ProgressGraph } from "./graphs";
import _ from "lodash";
import { useEffect } from "react";
import { db } from "../db";

export default function Report({release, profile, sett, roll }) {
  const [term, setTerm] = useState("First Term");
  const [reports, setReports] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    db.collection("examination_reports")
      .where("year", "==", year)
      .where("term", "==", term)
      .onSnapshot((qss) => {
        let temp = [];
        qss.forEach((doc) => {
          temp.push({ ...doc.data() });
        });
        setReports(temp);
      });
  }, [term, year]);

  const getTermReport = (sid, year, term) => {
    //check if result is release by admin.
    let status = _.find(
      release,
      (re) => re.year === year && (re.term === term || re.semester === term)
    );

    if (status === undefined) {
      return undefined;
    } else {
      let r_state = _.find(
        status.data,
        (rs) => rs.class === profile.class_admitted
      );
      if (r_state === undefined) {
        return undefined;
      } else {
        let checkedReport = _.find(
          reports,
          (std) => std.student_id === sid.toUpperCase()
        );

        if (checkedReport === undefined) {
          return undefined;
        } else {
          return checkedReport;
        }
      }
    }
  };

  const getYearReport = (sid, year) => {
    let status = _.find(release, (re) => re.year === year);
    if (status === undefined) {
      return [];
    } else {
      return _.filter(reports, (std) => std.student_id === sid.toUpperCase());
    }
  };

  const isJunior = (cls) => {
    return (
      cls.charAt(0) === "J" ||
      cls === "Basic 7" ||
      cls === "Basic 8" ||
      cls === "Basic 9" ||
      cls === "Basic 10"
    );
  };

  const getCurrentTermReport = (sid) => {
    let status = _.find(
      release,
      (re) =>
        re.year === year &&
        (re.term === sett.current_term || re.semester === sett.current_semester)
    );
    if (status === undefined) {
      return undefined;
    } else {
      let r_state = _.find(
        status.data,
        (rs) => rs.class === profile.class_admitted
      );
      if (r_state === undefined) {
        return undefined;
      } else {
        //might check fees status here before
        let thisTerm = _.find(reports, (std) => {
          return (
            std.student_id === sid.toUpperCase() &&
            std.class_admitted === profile.class_admitted &&
            std.term === sett.current_term
          );
        });
        if (thisTerm === undefined) {
          return undefined;
        } else {
          return thisTerm;
        }
      }
    }
  };

  const printReport = () => {
    const exam = getTermReport(profile.student_id, year, term);
    const exams = _.filter(
      reports,
      (ex) => ex.class_admitted === profile.class_admitted
    );
    
    if(exam === undefined){
      return
    }

    getImage(profile.profile_image, exam);

    generate_exams(exams, exam, profile.class_category, "download");
  };

  const report = getCurrentTermReport(profile.student_id);
  const currentReport = getTermReport(profile.student_id, year, term);

  const yearReport = getYearReport(profile.student_id, year);

  
  return (
    <React.Fragment>
      <div className="inner-nodes">
        <Header
          content="Dashboard"
          subheader="Academic Reports"
          className="headers"
        />
        <Grid centered verticalAlign="middle">
          <Grid.Column mobile={14}>
            <div style={{ boxShadow: "2px 2px 5px #ccc", padding: "5px" }}>
              <Grid columns={2}>
                <Grid.Column>
                  <List animated relaxed divided verticalAlign="middle">
                    <List.Item>
                      <List.Icon name="map pin" size="large" color="green" />
                      <List.Content>
                        <List.Header as="a">
                          Year: {sett.academic_year}
                        </List.Header>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon
                        name="id badge outline"
                        size="large"
                        color="orange"
                      />
                      <List.Content>
                        <List.Header as="a">Position: N/A </List.Header>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name="chart bar" size="large" color="grey" />
                      <List.Content>
                        <List.Header as="a">
                          Score:{" "}
                          {report === undefined
                            ? "N/A"
                            : report.overrall_aggregate}
                        </List.Header>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column>
                  <List animated relaxed divided verticalAlign="middle">
                    <List.Item>
                      <List.Icon
                        name="calendar alternate outline"
                        size="large"
                        color="teal"
                      />
                      <List.Content>
                        <List.Header as="a">
                          Term: {report === undefined ? "N/A" : report.term}
                        </List.Header>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon
                        name="id badge outline"
                        size="large"
                        color="orange"
                      />
                      <List.Content>
                        <List.Header as="a">
                          Class:
                          {report === undefined
                            ? profile.class_admitted
                            : report.class_admitted}
                        </List.Header>
                      </List.Content>
                    </List.Item>

                    <List.Item>
                      <List.Icon name="users" size="large" color="blue" />
                      <List.Content>
                        <List.Header as="a">No. on Roll: {roll}</List.Header>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
              </Grid>
            </div>
          </Grid.Column>

          <Grid.Column mobile={14}>
            <div style={{ boxShadow: "2px 2px 5px #ccc", padding: "10px" }}>
              <Form>
                <Form.Group widths="equal">
                  <Form.Select
                    value={term}
                    label="Select Term"
                    required
                    onChange={(e, target) => setTerm(target.value)}
                    options={
                      profile.class_category === "junior_high"
                        ? semesters
                        : terms
                    }
                  />

                  <Form.Select
                    label="Select Year"
                    value={year}
                    disabled={!term}
                    onChange={(e, target) => {
                      setYear(target.value);
                      getTermReport(profile.student_id, year, term);
                    }}
                    required
                    options={years}
                  />
                </Form.Group>
              </Form>

              <div>
                {currentReport === undefined ? (
                  <Header
                    content="Report Not Available"
                    subheader="Not released by Admin Yet"
                    textAlign="center"
                    as="h3"
                  />
                ) : (
                  <>
                    <Header
                      content="Examination Report"
                      subheader={term + " / " + year}
                    />
                    <Table striped singleLine unstackable>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Course</Table.HeaderCell>
                          <Table.HeaderCell>Grade</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body className="white">
                        <Table.Row>
                          <Table.Cell>Mathematics</Table.Cell>
                          <Table.Cell>{currentReport.mgr}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>Int. Science</Table.Cell>
                          <Table.Cell>{currentReport.sgr}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>English Lang.</Table.Cell>
                          <Table.Cell>{currentReport.egr}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>History</Table.Cell>
                          <Table.Cell>{currentReport.hgr}</Table.Cell>
                        </Table.Row>

                        {isJunior(currentReport.class_admitted) ? (
                          <React.Fragment>
                            <Table.Row>
                              <Table.Cell>Social S.</Table.Cell>
                              <Table.Cell>{currentReport.ssgr}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>B D T</Table.Cell>
                              <Table.Cell>{currentReport.bdgr}</Table.Cell>
                            </Table.Row>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <Table.Row>
                              <Table.Cell>O.W.O.P</Table.Cell>
                              <Table.Cell>{currentReport.owgr}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell>C.Arts</Table.Cell>
                              <Table.Cell>{currentReport.cragr}</Table.Cell>
                            </Table.Row>
                          </React.Fragment>
                        )}

                        <Table.Row>
                          <Table.Cell>Fante</Table.Cell>
                          <Table.Cell>{currentReport.fgr}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>French</Table.Cell>
                          <Table.Cell>{currentReport.fngr}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>ICT</Table.Cell>
                          <Table.Cell>{currentReport.ictgr}</Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>Aggregate</Table.Cell>
                          <Table.Cell>
                            {currentReport.overrall_aggregate}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>
                            <Button
                              onClick={printReport}
                              icon
                              color="twitter"
                              size="tiny"
                            >
                              {" "}
                              <Icon name="download" /> Download Report{" "}
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </>
                )}
              </div>

              <Segment raised>
                <ProgressGraph report={yearReport} profile={profile} />
              </Segment>
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
}
