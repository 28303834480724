import React from 'react'
import {Segment, Table, Form, Header, Button, Icon} from 'semantic-ui-react'
import {days} from '../components/sub-components'
import _ from 'lodash'

export default function TimeTable({table,file, profile}){

    const [day, setDay] = React.useState(days[new Date().getDay()].value)

    const getTable = (day) => {
        let classT = _.find(table,(td) => {return new Date(td.date_set).getFullYear() === new Date().getFullYear()  && td.day === day && td.class_name === profile.class_admitted})

        return classT === undefined ? undefined : classT;
    }
           
            

            let ccT = getTable(day)
    return(
        <React.Fragment>
            <Header content="Time Table" subheader="Select day to view Lessons" className="headers" />
                <div className="inner-nodes">
                    <Segment raised className="padded">
                        <Form>
                        <Form.Select onChange={(e, target) =>{setDay(target.value)}} value={day} label="Select Day" placeholder="Select Day" options={days} />
                        </Form>
                        {ccT === undefined ?
                        <Header as="h3" textAlign="center" content="Table Not Available" subheader="Day might be off or table not Set Yet!"/> : 
                        <Table unstackable striped>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell>Lesson 1</Table.Cell>
                                    <Table.Cell>{ccT.lesson_1}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Lesson 2</Table.Cell>
                                    <Table.Cell>{ccT.lesson_2}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Lesson 3</Table.Cell>
                                    <Table.Cell>{ccT.lesson_3}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Lesson 4</Table.Cell>
                                    <Table.Cell>{ccT.lesson_4}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Lesson 5</Table.Cell>
                                    <Table.Cell>{ccT.lesson_5}</Table.Cell>
                                </Table.Row>

                                <Table.Row>
                                    <Table.Cell>Lesson 6</Table.Cell>
                                    <Table.Cell>{ccT.lesson_6}</Table.Cell>
                                </Table.Row>
                            
                            {ccT.lesson_7 !== undefined ? 
                                <Table.Row>
                                    <Table.Cell>Lesson 7</Table.Cell>
                                    <Table.Cell>{ccT.lesson_7}</Table.Cell>
                                </Table.Row> : null}
                            </Table.Body>
                        </Table>}
                        <Button disabled={file === undefined} as='a' target="_blank" rel="noopener noreferrer" href={file.table_file} size="tiny" color="teal"><Icon name="download" /> Download Time Table</Button>
                    </Segment>
                </div>

        </React.Fragment>
    )
}